.header2{
    position: relative;
    display: inline-block;
    
    

}
.header2::before{
    content: ' ';
    position: absolute;
    width: 34px;
    height: 4px;
    background-color: #d8d433;
    left: -40px;
    top: 12px;
    clear: both;
    
}
