.my-nav {
    height: 100%;
    background-color: #023949;
    color: #ffffff;
    
}
  .my-nav .nav-item {
    
    color: #ffffff;
    transition: all 0.5s ease-in-out;
  }
  .my-nav .nav-item .dropdown-item {
    
    padding: 5px 10px;
  }
  .my-nav .nav-item:hover {
    
    border-bottom: 3px solid #00d0b6;
  }
  .my-nav .nav-link {
    color: #ffffff;
  }
  
  .active {
    background-color: #00d0b6;
    padding: 4px;
    border-radius: 2px;
    color: #ffffff;
  }
  
  .navbar-toggler {
    padding-top: 8px;
    width: 28px;
    height: 20px;
    position: relative;
    transition: 0.5s ease-in-out;
  }
  
  .navbar-toggler,
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
  }
  
  .navbar-toggler span {
    margin: 0;
    padding: 0;
  }
  
  .toggler-icon {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background: #5ca703;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  
  .mid-bar {
    margin-top: 0px;
  }
  
  /* when navbar is clicked*/
  .navbar-toggler .top-bar {
    margin-top: 0;
    transform: rotate(135deg);
  }
  
  .navbar-toggler .mid-bar {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  
  .navbar-toggler .bottom-bar {
    margin-top: 0;
    transform: rotate(-135deg);
  }
  
  /* state when navbar iscollapsed*/
  .navbar-toggler.collapsed .top-bar {
    margin-top: -10px;
    transform: rotate(0deg);
  }
  
  .navbar-toggler.collapsed .mid-bar {
    opacity: 1;
    width: 24px;
    filter: alpha(opacity=100);
  }
  
  .navbar-toggler.collapsed .bottom-bar {
    margin-top: 10px;
    width: 20px;
    transform: rotate(0deg);
  }
  
  .top__header {
    background-color: #001d38;
    padding: 12px 0;
  }
  
  .logo {
    height: 6rem;
    width: 8rem;
    -o-object-fit: contain;
       object-fit: contain;
  }/*# sourceMappingURL=Header.css.map */

  .profile__logo {
    object-fit: contain;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgb(208, 201, 201);
}

.person__logo {
    cursor: pointer !important;
    color: white !important;
}

.profile {
    position: relative;
}


.profile__actions {
    position: absolute;
    top: 130%;
    left: 0;
    width: 150px;
    z-index: 101;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 4px;
    display: none;
    cursor: pointer;
}

.profile__actions div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.show__profileAction {
    display: block ;
}